<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con" v-if="!$Util.isEmpty(items)">
          <component-board-index :items="items" />
          <div v-observe-visibility="visibilityChanged"></div>
        </div>
        <empty-index v-else :content="'내역이 없습니다.'" />
      </div>
    </div>
  </main>
</template>

<script>
import { getCurrentInstance, reactive, toRefs } from 'vue'
import ComponentBoardIndex from '@/components/views/board'
import EmptyIndex from '@/components/views/empty/full'

export default {
  name: 'menu-board-faq-index',
  components: { ComponentBoardIndex, EmptyIndex },
  description: '자주 묻는 질문 리스트 페이지',
  setup() {
    const { proxy } = getCurrentInstance()
    const state = reactive({
      params: {
        page: 1,
        limit: 10,
        type: 2
      },
      pageInfo: {},
      items: []
    })
    const fnListBox = async () => {
      try {
        const res = await proxy.$BoardSvc.fetchUserBoardList(state.params)
        if (res.code === 1) {
          state.pageInfo = res.entity.pageInfo
          state.items = state.items.concat(res.entity.list)
          state.params.page++
        }
      } catch (err) {
        console.error(err)
      }
    }

    const visibilityChanged = async isVisible => {
      if (isVisible) {
        if (state.pageInfo.totalPage < state.params.page) return false
        await fnListBox()
      }
    }
    fnListBox()
    return { ...toRefs(state), fnListBox, visibilityChanged }
  }
}
</script>

<style scoped></style>
