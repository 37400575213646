<template>
  <div class="list_page2">
    <div class="box_con">
      <div class="box_list_wrap">
        <div class="box_shadow m_8">
          <div class="box box_one">
            <div
              class="box_list_wrap"
              v-for="(item, index) in items"
              :key="index"
              @click="fnView(item)"
            >
              <div class="box_list">
                <div class="list_ttl">
                  <div class="left">
                    <div class="m_4" v-show="item.board.type === 1">
                      <span class="build_name">{{ item.building.name }}</span>
                    </div>
                    <p>{{ item.board.title }}</p>
                    <div class="price_box" v-show="item.board.type === 1">
                      <span class="price_txt">{{
                        $dayjs(item.board.regDate).format('YYYY.MM.DD')
                      }}</span>
                    </div>
                  </div>
                  <div class="middle_box flex1none">
                    <div class="icon"></div>
                  </div>
                </div>
              </div>
              <div class="line_grey none"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'

export default {
  name: 'component-board-index',
  description: '게시판 리스트 컴포넌트',
  props: {
    items: [Array],
    default: () => {}
  },
  setup() {
    const router = useRouter()

    const fnView = item => {
      router.push({
        path: '/menu/board/view',
        query: { boardNo: item.board.boardNo, type: item.board.type }
      })
    }

    return { fnView }
  }
}
</script>

<style scoped></style>
